import React from "react"
//components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/home/Hero"
import About from "../components/home/About"
import Products from "../components/home/Products"
import Partners from "../components/home/Partners"
import Contact from "../components/home/Contact"
//data
import pageData from "../data/pageData"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Hitss Argentina" />
    <Hero />
    <About aboutData={pageData.home.about} />
    <Products productsData={pageData.home.products} />
    <Partners />
    <Contact location={location} />
  </Layout>
)

export default IndexPage
