import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from "gatsby-image"
import { Grid } from '@material-ui/core'
import { MdLanguage } from 'react-icons/md'

const Hero = ({ aboutData }) => {
  const data = useStaticQuery(graphql`
    query AboutImage{
      file(name: {eq: "about"}) {
        childImageSharp {
          fluid (maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
          fixed (height: 480) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <section>
      <Grid container spacing={0}>
        <StyledCol item xs={12} sm={6} lg={5}>
          <div>
            <MdLanguage className='icon' />
            <div className='content' dangerouslySetInnerHTML={{ __html: aboutData }}></div>
          </div>
        </StyledCol>
        <ImageCol item xs={12} sm={6} lg={7}>
          <Img
            fixed={data.file.childImageSharp.fixed}
            objectFit='cover'
            style={{ overflow: 'hidden', width: '100%', height: '100%', display: 'block' }}
            imgStyle={{ width: '100%', margin: 0 }}
            alt="Hitss team" />
        </ImageCol>
      </Grid>
    </section>
  )
}

export default Hero

const StyledCol = styled(Grid)`
  color: ${props => props.theme.colors.text.light};
  background: rgb(132,192,82);
  background: linear-gradient(325deg, rgba(132,192,82,1) 0%, rgba(87,185,149,1) 52%, rgba(9,162,173,1) 100%);
  padding: 1.25rem 1rem 2.5rem;
  @media screen and (min-width: 600px){
    padding: 3.75rem 3.75rem 7rem;
  }
  .icon{
    font-size: 2rem;
    @media screen and (min-width: 600px){
      font-size: 4rem;
    }
  }
  .content{
    p{
      font-size: .9rem;
      line-height: 1.75rem;
      margin: 0;
      @media screen and (min-width: 600px){
        font-size: 1.3rem;
      }
    }
  }
`
const ImageCol = styled(Grid)`
  display: none;
  @media screen and (min-width: 600px){
    display: block;
  }
`